
import './App.css';

import {useRoutes} from 'hookrouter';

import Start from './Start.js';
import NotFound from './NotFound';
import Sidebar from './Sidebar';
import Scuola from './Scuola'
import AnagraficaDocente from './AnagraficaDocente'
import Classi from './Classi'
import Classe from './Classe'
import Privacy from './Privacy'
import Riepilogo from './Riepilogo'
import InvioCompletato from './InvioCompletato'
import UtenteScuolaPark from './UtenteScuolaPark'
import { ssprefix } from './Config';

const routes = {
    '/' : () => <LandingPage/>,
    '/:uid': ({uid}) => <Start uid={uid} />,
    '/:uid/scuola': ({uid}) => <Scuola uid={uid}/>,
    '/:uid/anagrafica-docente': ({uid}) => <AnagraficaDocente uid={uid}/>,
    '/:uid/classi': ({uid}) => <Classi uid={uid}/>,
    '/:uid/privacy': ({uid}) => <Privacy uid={uid}/>,
    '/:uid/classe/:idc': ({uid,idc}) => <Classe idc={idc} uid={uid}/>,
    '/:uid/riepilogo' : ({uid}) => <Riepilogo uid={uid}/>,
    '/:uid/utente-scuolapark' : ({uid}) => <UtenteScuolaPark uid={uid}/>,
    '/:uid/invio-completato': ({uid}) => <InvioCompletato uid={uid}/>
};

function LandingPage() {
  return (
    <div class="sp_form">
          <h2>Iscrizione ad eCAP</h2>
          <p>Per iscrivere le tue classi ad eCAP, utilizza il link che ti è stato inviato via email.</p>
    </div>

  );
}

function App() {
  const routeResult = useRoutes(routes);

  if (sessionStorage.getItem(ssprefix+"statosezioni")===null) {
    const stato_sezioni = [false,false,false,false,false,false,false];
    sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
  }

  return (
    <>
    <div className="container-fluid">
      <Sidebar/>
      <main>
        { routeResult || <NotFound/>}
      </main>
    </div>
    <div id="modal_area"></div>
    </>
  );
}

export default App;
