import {navigate} from 'hookrouter';
import { ssprefix } from './Config';
import {Formik,  Form, Field} from 'formik';


export default function Privacy(params) {

  const form_name = ssprefix+"privacy";
  let initial = {};
  if (sessionStorage.getItem(form_name) !== null) {
    initial = JSON.parse(sessionStorage.getItem(form_name));
  }

  return(
    <div class="sp_form">
   
      <Formik
        initialValues={initial}
        validate={values => {
          // const errors = {}
          
          // if (values.password.length < 5) {
          //   errors.password = "Password troppo corta!";
          // }

          // return errors;
          return {};
        }}

        onSubmit={(values, { setSubmitting }) => {

          setTimeout(() => {
 
            sessionStorage.setItem(form_name,JSON.stringify(values))
            let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
            stato_sezioni[5] = true;
            sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
 
            setSubmitting(false);

            navigate("./riepilogo")
 
          }, 400);
 
        }}>
          {
            (props) => (
              <Form>
               

            <h2>Autorizzazione al trattamento dei dati personali</h2>
            <p>Il tuo consenso è obbligatorio per noi per poter raccogliere i dati necessari all'iscrizione della tua classe (il tuo nominativo, indirizzo mail e numero di telefono personali) e per poterti inviare le successive comunicazioni per la programmazione delle attività didattiche.</p>

            <div className="card">
                <div className="card-body">
                    <div className="form-check">
                         <Field type="checkbox" name="autorizz-privacy" value="ok" id="autorizz-privacy" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-privacy">
                        Dichiaro di aver preso visione di tutte le informazioni relative al progetto e di aver letto l'offerta didattica contenente le attività proposte
                        </label>
                    </div>

                    <div className="form-check mt-3">
                        <Field type="checkbox" name="ack-priorita" value="ok" id="ack-priorita" required className="form-check-input" />
                        <label className="form-check-label" for="ack-priorita">
                        Dichiaro di aver preso atto e compreso che i posti per le attività didattiche sono limitati e che sarà tenuto in considerazione l'ordine di registrazione delle adesioni.
                        </label>
                    </div>
                   
                
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">

                    <p style={{fontSize:".85rem"}}>
                      Dichiaro di aver preso visione della <b><a href="https://static.ecap.gruppocap.it/privacy/privacy-policy.pdf">Privacy Policy</a></b> di eCAP.
                    </p>
                    <div className="form-check">
                        <Field type="checkbox" name="autorizz-gdpr" value="ok" id="autorizz-gdpr" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-gdpr">
                        Accetto
                        </label>
                    </div>

                
                </div>
            </div>

            <p className="mt-3"><button type="submit" className="btn btn-primary"><i className="bi bi-arrow-right"></i> Vai al riepilogo</button></p>

              </Form>
            )
          }
        </Formik>      
    </div>
)

}